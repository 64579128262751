import React from 'react'

export default function Impressum() {
  return (
    <div className="container mt-5 mb-5 text-dark" style={{textAlign: 'left'}}>
       <p>Angaben gemäß § 5 TMG:</p>
       <section>
        <h4>1. Anbieter:</h4>
        <p>Anwaltskanzlei Rabinovich<br />Merianstraße 19<br />60316 Frankfurt am Main<br />Tel.: +49 (0)15203373871<br />E-Mail: info@kanzlei-rbr.com</p>
       </section>
       <section>
        <h4>2. Zulassung</h4>
        <p>Rechtsanwalt Boris Rabinovich ist nach dem Recht der Bundesrepublik Deutschland zugelassen. Zuständige Kammer: Rechtsanwaltskammer Frankfurt am Main, Bockenheimer Anlage 36, 60322 Frankfurt am Main an.</p>
       </section>
       <section>
        <h4>3. Berufsrechtliche Regelungen:</h4>
        <p>Die für Rechtsanwälte maßgeblichen berufsrechtlichen Regelungen können über die Homepage der Bundesrechtsanwaltskammer <a href="https://brak.de/">www.brak.de</a> in der Rubrik „Berufsrecht“ eingesehen und abgerufen werden. Hierzu zählen insbesondere:</p>
        <ul>
            <li><a href="http://www.brak.de/fuer-anwaelte/berufsrecht">BRAO Bundesrechtsanwaltsordnung</a></li>
            <li><a href="http://www.brak.de/fuer-anwaelte/berufsrecht">BORA	Berufsordnung für Rechtsanwälte</a></li>
            <li><a href="http://www.brak.de/fuer-anwaelte/berufsrecht">FAO Fachanwaltsordnung</a></li>
            <li><a href="http://www.gesetze-im-internet.de/rvg">RVG Gesetz über die Vergütung der Rechtsanwältinnen und Rechtsanwälte</a></li>
            <li><a href="http://www.brak.de/fuer-anwaelte/berufsrecht">CCBE Berufsregeln der Rechtsanwälte der Europäischen Gemeinschaft</a></li>
            <li><a href="http://www.brak.de/fuer-anwaelte/berufsrecht">EuRAG Gesetz über die Tätigkeit europäischer Rechtsanwälte in Deutschland</a></li>
        </ul>
       </section>
       <section>
        <h4>4. Berufshaftpflichtversicherung:</h4>
        <p>Versicherungsgesellschaft Provinzial<br />Provinzialplatz 1<br />40591 Düsseldorf<br />Tel. 0211 978-0<br />Räumlicher Geltungsbereich: Deutschland.</p>
       </section>
       <section>
        <h4>5. Vermeidung von Interessenkonflikten</h4>
        <p>Die Wahrnehmung widerstreitender Interessen ist Rechtsanwälten aufgrund berufsrechtlicher Regelungen untersagt (§ 43a Abs. 4 BRAO). Vor Annahme eines Mandates wird deshalb immer geprüft, ob ein Interessenkonflikt vorliegt.</p>
       </section>
       <section>
        <h4>6. Außergerichtliche Streitschlichtung</h4>
        <p>Bei Streitigkeiten zwischen Rechtsanwälten und ihren Auftraggebern besteht auf Antrag die Möglichkeit der außergerichtlichen Streitschlichtung bei der regionalen Rechtsanwaltskammer (gemäß § 73 Abs. 2 Nr. 3 i.V.m. § 73 Abs. 5 BRAO) oder bei der Schlichtungsstelle der Rechtsanwaltschaft bei der Bundesrechtsanwaltskammer (§ 191f BRAO), im Internet zu finden über die Homepage der <a href="http://www.brak.de">Bundesrechtsanwaltskammer</a> oder per E-Mail: <a href="mailto:schlichtungsstelle@brak.de">schlichtungsstelle@brak.de</a>.</p>
       </section>
    </div>
  )
}
